<script>
export default {
  name: "CheckoutConfirmed",
};
</script>

<template>
  <!-- inner-hero-section start -->
  <div class="inner-hero-section style--four">
    <div class="bg-shape">
      <img src="@/assets/images/elements/inner-hero-shape-2.png" alt="image" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <ul class="page-list">
            <li>
              <router-link :to="{ name: 'home' }" tag="a">
                {{ $t("text.home") }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'shopping-cart' }" tag="a">
                {{ $t("text.my-cart") }}
              </router-link>
            </li>
            <li class="active">
              {{ $t("text.checkout-confirmed") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- inner-hero-section end -->

  <section class="pb-120 mt-minus-150">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="faq-top-wrapper pt-120">
            <div class="section-header text-center">
              <span class="section-sub-title">
                {{ $t("text.congratulations") }}
              </span>
              <h2 class="section-title">
                {{ $t("text.checkout-confirmed") }}
              </h2>
              <p>
                {{ $t("text.checkout-confirmed-description") }}
              </p>
            </div>
            <ul
              class="nav nav-tabs cmn-tabs justify-content-center"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <router-link
                  :to="{ name: 'home' }"
                  tag="a"
                  class="nav-link active"
                  id="ticket-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="ticket"
                  aria-selected="false"
                >
                  {{ $t("text.home") }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>